
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$green: #07bc0c;
$warning: #ffc107;

.wrapper {
  background: var(--page-background-color);
  z-index: 100;
  text-align: center;

  .content {
    max-width: 350px;
    margin: auto;
    &.white {
      color: white;
    }
    .completed {
      min-width: 95px;
      min-height: 95px;
      width: 95px;
      height: 95px;
      border-radius: 50%;
      background: var(--primary);
      margin: 35px auto 70px;
      box-shadow: 0 0 0 10px rgba(var(--primary), 0.6), 0 0 0 20px rgba(var(--primary), 0.35),
        0 0 0 30px rgba(var(--primary), 0.15);
      display: flex;
      align-content: center;

      &:before {
        content: '';
        position: relative;
        top: -7px;
        width: 15px;
        margin: auto;
        height: 40px;
        border-width: 4px;
        border-color: #fff;
        border-right-style: solid;
        border-bottom-style: solid;
        transform: rotate(45deg);
      }
    }

    .completedGreen {
      min-width: 95px;
      min-height: 95px;
      width: 95px;
      height: 95px;
      border-radius: 50%;
      background: $green;
      margin: 35px auto 70px;
      box-shadow: 0 0 0 10px rgba($green, 0.6), 0 0 0 20px rgba($green, 0.35),
        0 0 0 30px rgba($green, 0.15);
      display: flex;
      align-content: center;

      &:before {
        content: '';
        position: relative;
        top: -7px;
        width: 15px;
        margin: auto;
        height: 40px;
        border-width: 4px;
        border-color: #fff;
        border-right-style: solid;
        border-bottom-style: solid;
        transform: rotate(45deg);
      }
    }

    .cancelled {
      min-width: 95px;
      min-height: 95px;
      width: 95px;
      height: 95px;
      border-radius: 50%;
      background: $warning;
      margin: 35px auto 70px;
      box-shadow: 0 0 0 10px rgba($warning, 0.6), 0 0 0 20px rgba($warning, 0.35),
        0 0 0 30px rgba($warning, 0.15);
      display: flex;
      align-content: center;

      &:before {
        content: '';
        position: relative;
        top: -7px;
        width: 15px;
        margin: auto;
        height: 40px;
        border-width: 4px;
        border-color: #fff;
        border-right-style: solid;
        border-bottom-style: solid;
        transform: rotate(45deg);
      }
    }

    .issue {
      text-align: center;
      margin-bottom: 25px;
      z-index: 350;

      .warning {
        margin: 0 auto 35px;
      }

      span {
        display: block;
        max-width: 245px;
        margin: auto;
        font-weight: 600;
      }
    }

    .landscope {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;

      .content {
        max-width: 250px;
        border: 1px solid;
        border-radius: 5px;
        margin: auto;
        padding: 30px;
        text-align: center;

        img {
          display: block;
          width: 60px;
          margin: 25px auto 0;
        }
      }
    }

    ul,
    ol {
      margin: 0;
      padding: 0;
      padding-left: 30px;
      text-align: left;

      li {
        margin-bottom: 10px;
      }
    }
  }
}
