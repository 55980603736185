
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  height: 100%;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);

  .heading {
    font-family: var(--heading-font-family) !important;
    //color: var(--heading-text-color) !important;
    color: white;
    margin-top: 1.5rem; //24
    text-align: left;
    font-size: 2rem; //32
    span {
      color: #00d996;
    }
  }

  .description {
    margin-top: 1.5rem; //24
    text-align: left;
    color: white;
  }

  .space {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }

  .link {
    text-decoration: none;
    margin-top: 1rem; //16
    margin-bottom: 1.5rem; //24
    color: white;
    text-align: center;
    font-weight: 600;
    cursor: pointer;

    img {
      position: relative;
      width: 2rem;
      height: 2rem; //32
      margin-top: -0.1875rem; //3
    }
  }

  .begin {
    width: 100%;
    height: 2.8125rem; //45
    background: #00d996;
    border: 1px solid #00d996;
    border-color: #00d996;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    font-weight: 500;
    color: white;
    border-radius: 0.625rem; //10
    z-index: 2;
  }
}
