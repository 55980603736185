
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  text-align: left;
  position: absolute;
  top: 35%;
  padding: 2rem;

  .heading {
    font-family: var(--heading-font-family) !important;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
  }

  .title {
    font-size: 14px;
  }

  .img-container {
    img {
      height: 40px;
    }
  }
}
