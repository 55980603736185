
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$fill: var(--primary);
@if ($version >= 3) {
  $fill: #000;
}
.wrapper {
  text-align: center;
  margin-top: -15px;

  .steps {
    margin: 15px auto 0;
    max-width: 545px;

    .item {
      position: relative;
      padding: 25px 20px 20px;

      h5 {
        top: 15px;
        position: absolute;
        font-family: var(--base-font-family) !important;
        font-weight: 600;
      }

      svg {
        fill: $fill;
        margin-bottom: 5px;
      }
    }
  }
}
