
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  @media all and (min-width: 500px) {
    text-align: center;
  }
  .title {
    margin-bottom: 25px;
    font-weight: 500;
  }

  label {
    @media all and (min-width: 500px) {
      display: block;
    }
  }

  .input {
    &.halfWith {
      max-width: 50%;
      margin-right: 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
