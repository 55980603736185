
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .container {
    width: 400px;
    margin: 120px auto 0;

    .logo {
      margin: 0 auto 35px;
      display: block;
    }

    .block {
      background: #fff;
      box-shadow: 0 2px 20px #eee;

      .title {
        padding: 20px;
        text-align: center;
        text-transform: uppercase;
        background: var(--primary);
        color: #fff;
      }

      .content {
        padding: 20px 35px;

        p {
          opacity: 0.8;
          font-size: 14px;
          text-align: center;
          margin: 0 auto 30px;
          max-width: 300px;

          a {
            color: inherit;
            text-decoration: underline;
          }
        }

        .input {
          display: flex;

          .code {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
