
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  padding: 0px 15px;
  margin-top: -15px;
  text-align: left;

  .title {
    margin-bottom: 30px;
  }

  .container {
    padding: 0 15px;
    max-width: 440px;
  }

  .input {
    &.halfWith {
      max-width: 50%;
      margin-right: 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .isCorrect {
    display: flex;

    .input {
      margin: 0;
      margin-bottom: 15px;
    }

    .checkbox {
      margin-top: 15px;
      font-size: 14px;
    }
  }
}
@media (max-width: 650px) {
  .wrapper {
    padding: 0;
  }
}
