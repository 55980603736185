
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
  }

  .description {
    text-align: left;
  }
}
