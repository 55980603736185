$c-primary: #001f00;
$c-secandary: #001f00;
$dots: false;
$version: 3.1;

$body-color-overrided: #001f00;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$heading-color-overrided: #000000;

@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-Light.ttf');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}


$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Montserrat', sans-serif;