
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/bloommoney/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  [class^='Message-style-content'] {
    max-width: 100% !important;
  }

  ul {
    display: block;
    margin: 0 !important;
    padding: 0 !important;

    li {
      display: block;
      float: left;
      width: 50%;
      position: relative;
      padding: 15px 13px;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      line-height: 0;
      height: 54px;
      margin-bottom: 0 !important;
      text-transform: capitalize;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        position: relative;
        margin-right: 15px;
        background-image: url(_img('icons/png/status@2x.png'));
        background-size: 24px;
      }

      &.rFailed:before {
        background-position: 0 -24px;
      }

      &.bgSuccess {
        background: $c-green;

        &:before {
          background-position: 0 -48px;
        }
      }

      &.bgDanger {
        background: $c-danger;

        &:before {
          background-position: 0 -72px;
        }
      }

      &.bgOrange {
        background: $c-warning;

        &:before {
          background-position: 0 -96px;
        }
      }

      &.title:before {
        display: none;
      }

      &.tl {
        font-size: 13px;
      }

      &.title {
        padding-left: 0;
        padding-right: 0;
        color: inherit;
        line-height: 18px;
        margin-bottom: 5px !important;

        b {
          font-size: 11px;
          margin-top: -5px;
        }
      }

      &.status {
        color: $c-green;
        margin-bottom: 15px !important;
        display: inline-flex;
        align-content: center;
        align-items: center;
      }

      &.status.rFailed {
        color: $c-danger;
      }

      small {
        display: block;
        font-size: 10px;
        font-weight: 600;
        margin-top: -4px;
        margin-bottom: -8px;
        padding-left: 39px;
      }

      .text {
        position: relative;
        margin-top: -16px;
        display: block;
        margin-left: 39px;
      }
    }
  }

  .textSuccess {
    color: $c-green;
  }

  .textDanger {
    color: $c-danger;
  }
}
